import React from 'react';
import { graphql } from 'gatsby';

import ParagraphComponent from '@core/helpers/paragraph-helpers';
import { isContentTypeHero } from '@core/helpers/hero';

import Breadcrumb from '@shared/breadcrumbs/breadcrumbs';

import { Graphql } from '@models/graphql';

const { technologies: crumbs } = require('@config/breadcrumbs');

type TechnologyPageProps = {
  language: string,
  data: {
    technology: Graphql
  };
};

export default function TechnologyPage(
  { data, language }: TechnologyPageProps
): React.ReactElement {
  const { technology } = data;
  const paragraphs = technology?.relationships?.paragraphs;

  if (paragraphs && paragraphs.length <= 0) {
    return <></>;
  }

  const content = paragraphs?.map(
    (node) => ParagraphComponent({ ...node, path: technology.path }, language)
  );

  const hero = paragraphs && isContentTypeHero(paragraphs[0]) ? content?.shift() : <></>;

  return (
    <>
      <div className="page-components-container">
        {hero}
        <Breadcrumb crumbs={crumbs.concat([{ label: technology.title, link: '#' }])} />
        {content}
      </div>
    </>
  );
}

export const query = graphql`
query($id: String!) {
  technology: nodeTechnology(id: { eq: $id }) {
    title
    body {
      processed
      summary
    }
    path {
      alias
      langcode
    }
    relationships {
      paragraphs: field_content_main {
        type: __typename
        ...CtaParagraph
        ...HeroParagraph
        ...HeroSliderParagraph
        ...BannerParagraph
        ...BannerTalentParagraph
        ...BannerAdvParagraph
        ...QuoteSliderParagraph
        ...QuoteParagraph
        ...IntroTextParagraph
        ...IntroBlockParagraph
        ...TimelineParagraph
        ...CardsParagraph
        ...BoxesParagraph
        ...GridParagraph
        ...ClientsParagraph
        ...TechnologiesParagraph
        ...TabsParagraph
        ...TableParagraph
        ...WysiwygParagraph
        ...ViewParagraph
        ...FeaturedContentParagraph
        ...BannerFormParagraph
        ...BannerTalentApplyParagraph
        ...EmergencyModalParagraph
        ...BannerVideoParagraph
        ...WebFormParagraph
        ...CollageParagraph
        ...FabParagraph
        ...ImageSliderParagraph
        ...OfficesParagraph
      }
    }
  }
}`;
